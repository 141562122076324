
export default class Steps {
  constructor(element) {
    this.element = element;

    this.counter =  0;

    this.items = this.element.querySelectorAll('[data-step-id]');
    this.gotos = this.element.querySelectorAll('[data-step-go-to]');
    this.options = this.element.querySelectorAll('[data-step-id] [data-step-option]');
    this.resumeRows = document.querySelectorAll('.c-resume__row');

    this.handleOptionClick = this.handleOptionClick.bind(this);
    this.handleGotoClick = this.handleGotoClick.bind(this);
  }

  mount() {
    this.update(this.counter);

    for (let i = 0; i < this.options.length; i++) {
      const option = this.options[i];
      option.addEventListener('click', this.handleOptionClick);
    }

    for (let i = 0; i < this.gotos.length; i++) {
      const goto = this.gotos[i];
      goto.addEventListener('click', this.handleGotoClick);
    }
  }

  unmount() {
  }

  hide() {
    // hide section
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      item.style.display="none";
      item.style.visibility="hidden";
    }
  }

  update(counter){
    this.hide();
    this.show(counter);
    this.resume(counter);

  }

  handleGotoClick(e) {
    const newCounter = parseInt(e.currentTarget.dataset.stepGoTo, 10) - 1;

    this.counter = newCounter;
    this.update(this.counter);
  }

  handleOptionClick() {
    if (!this.href) {
      this.counter= this.counter+1;
      this.update(this.counter);
    }
  }

  resume(counter) {
    for (let i = 0; i < this.resumeRows.length; i++) {
      const item = this.resumeRows[i];
      item.style.display="none";
      item.style.visibility="hidden";
    }

    if(counter === 0) {
      this.resumeRows[0].style.display="flex";
      this.resumeRows[0].style.visibility="visible";

    } else if(counter === 1) {
      this.resumeRows[0].style.display="flex";
      this.resumeRows[0].style.visibility="visible";

      this.resumeRows[1].style.display="flex";
      this.resumeRows[1].style.visibility="visible";

    } else {
      this.resumeRows[0].style.display="flex";
      this.resumeRows[0].style.visibility="visible";

      this.resumeRows[1].style.display="flex";
      this.resumeRows[1].style.visibility="visible";

      this.resumeRows[2].style.display="flex";
      this.resumeRows[2].style.visibility="visible";
    }
  }

  back() {
    this.counter= this.counter-1;
    this.update(this.counter);
  }

  show(counter) {
    // show active section
    this.items[counter].style.display="block";
    this.items[counter].style.visibility="visible";
  }
}
